import Vue from 'vue'
import Vuex from 'vuex'
import SecureLS from 'secure-ls'
import createPersistedState from 'vuex-persistedstate';
import system_data from './module/system'
import form_rules from './module/form_rules'
import authentication from './module/authentication'
import users from './module/user'
import business_categories from './module/data/business_categories'
import members_information from './module/data/members_information'
import business_owners from './module/data/business_owners'
import bank_depositories from './module/data/bank_depositories'
import deposit_slips from './module/data/deposit_slips'
import members_payments from './module/data/members_payments'
import loan from './module/data/loan'
import loan_payments from './module/data/loan_payments'
import business_brenches from './module/data/business_brenches'
import loan_deductions_payments from './module/data/loan_deductions_payments'
import transaction_months from './module/data/transaction_months'

const ls = new SecureLS({
  isCompression: false,
})
Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [createPersistedState(
    {
      key: 'GGC-NMAPI',
      paths: ['authentication'],
      storage: {
        getItem: (key) => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: (key) => ls.remove(key),
      },
    },
  )],
  state: {},
  mutations: {},
  actions: {},
  modules: {
    system_data,
    form_rules,
    authentication,
    users,
    business_categories,
    members_information,
    business_owners,
    bank_depositories,
    deposit_slips,
    members_payments,
    loan,
    loan_payments,
    business_brenches,
    loan_deductions_payments,
    transaction_months,
  },
})
